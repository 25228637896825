html,
body {
  height: 100%;
}

.react-datepicker__input-container input {
  display: block;
  height: -webkit-calc(1.5em + 0.75rem + 2px);
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.react-datepicker-popper {
  width: 30%;
}

.grey-3-color {
  color: #7f89a3;
}

/* 
  a bit of a ugly trick here were we want to hide the filter icon for a given
  header in a data-grid, but not the sorting icon. Mui did not give them specific
  classes, so we can't specifically target one without the other. We can't use
  the header options either, because to hide the icon, we have to disable the filtering,
  which is not what we want.
  The last solution that we have is to rely on the fact that the filter icon is displayed
  before the sorting icon (thus why I'm calling this dirty). 
  `~` is: don't display icons, except if they are preceded by another icon. 
 */
.data-grid-header-filter-hidden .MuiDataGrid-iconButtonContainer {
  display: none;
}
.data-grid-header-filter-hidden .MuiDataGrid-iconButtonContainer ~ .MuiDataGrid-iconButtonContainer {
  display: initial;
}

/* ReactMultiEmail */

.react-multi-email {
  border-radius: 0.5rem !important;
}

.react-multi-email:hover {
  border-color: #001159;
}

.react-multi-email [data-tag] {
  font-size: 16px;
}

/* ReactQuill */
.ql-container.ql-snow {
  background-color: white;
}

button[data-span="true"] {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  padding: 0;
  margin: 0;
}

/* General toast container styling */
.Toastify__toast-container {
  display: flex;
  justify-content: center;
  width: 100% !important;
}

/* General toast styling */
.Toastify__toast {
  width: fit-content !important;
}
